<template>
    <div>
    <div class="form-table">
        <!--信息展示组件-->
        <flow-overhaul-detail v-if="showFlowOverhaulDetail" ref="ref_flowOverhaul_detail"></flow-overhaul-detail>
        <overhaul-create v-if="showOverhaulApply" ref="ref_overhaul_apply"></overhaul-create>
        <overhaul-receive v-if="showOverhaulReceive" ref="ref_overhaul_receive"></overhaul-receive>

        <record-history v-if="showRecordHistory" :instance="currentData"/>
    </div>
        <!--end-->
        <div class="submit-btn-group button-fixed">
            <el-button @click="closeForm">关闭</el-button>
        </div>
    </div>
</template>

<script>
/**
 * 通用信息展示组件
 */
import FlowOverhaulDetail from '@/pages/unOverhaul/overhaulDetail';
import OverhaulCreate from '@/pages/unOverhaul/overhaulCreate.vue';
import OverhaulReceive from '@/pages/unOverhaul/overhaulReceive.vue';
import RecordHistory from '@/components/workflow/RecordHistory';
export default {
    components: {
        FlowOverhaulDetail,
        OverhaulCreate,
        OverhaulReceive,
        RecordHistory
    },
    data() {
        return {
            currentData: {},
            showFlowOverhaulDetail: false,
            showOverhaulApply: false,
            showOverhaulReceive: false,
            data: {},
            showRecordHistory: false
        };
    },
    created() {
        this.init(JSON.parse(sessionStorage.getItem(this.$route.query.id)));
    },
    methods: {
        init(item) {
            this.currentData = item;
            this.showRecordHistory = true;
            this.showFlowOverhaulDetail = true;
            this.$nextTick(() => {
                this.$refs.ref_flowOverhaul_detail.init(item);
            });
        },
        closeForm(type) {
            //this.$emit('closeForm', type);
            this.$root.$emit('fe-framework:tab:close', this.$route.fullPath);
        },
    }
};
</script>
<style>
    .form-table {
        height: calc(100vh - 170px);
        margin-bottom: 10px;
        overflow-y: scroll;
    }
    .button-fixed {
        margin:  0 !important;
    }
    .submit-btn-group {
        text-align: center;
        margin-top: 60px;
        margin-bottom: 60px;
    }
</style>
